import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation, Trans } from 'react-i18next'
import './css/contact.css'

const Contact = () => {
    const { t } = useTranslation()

    return(
        <>
            <Helmet>
                <title>{t('contact').toUpperCase()} — Riccardo Porcu' Photography</title>
            </Helmet>
            <div className='contact-container'>
                <div className='contact-inner-container'>
                    <h1>{t('contact')}</h1>
                    <br/>
                    <p>
                        <Trans i18nKey='contact-description' components={[
                            <a href='mailto:shotbyrix@gmail.com'>send an email at shotbyrix@gmail.com</a>,
                            <a href='https://www.instagram.com/shotbyrix' target='_blank' rel='noreferrer'>contact Riccardo through Instagram</a>
                        ]} />
                    </p>
                </div>
            </div>
        </>
    )
}

export default Contact