import React, { useEffect, useCallback, useState } from 'react'
import Carousel from './components/Carousel'
import { Helmet } from 'react-helmet'
import './css/home.css'

const Home = () => {
    const [homeImages, setHomeImages] = useState([])
    const getData = useCallback(() => {
        fetch('data/images.json', {
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(function(response) {
            return response.json()
        })
        .then(function(json) {
            setHomeImages([])
            json.forEach(image => {
                setHomeImages(oldArray => [...oldArray, image])
            })
        });
    }, [])
    
    useEffect(() => {
        getData()
    }, [getData])

    return (
        <>
            <Helmet>
                <title>Riccardo Porcu' Photography</title>
            </Helmet>
            <div className='home-container'>
                <Carousel className='carousel' images={homeImages} />
            </div>
        </>
    )
}

export default Home