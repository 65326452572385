import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as CloseMenu } from '../assets/x.svg'
import { ReactComponent as MenuIcon } from '../assets/menu.svg'
import { ReactComponent as Logo } from '../assets/logo.svg'
import { useTranslation } from 'react-i18next'
import '../css/navbar.css'

const Navbar = () => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)
    const toggleMobileMenu = () => setMobileMenuOpen(!isMobileMenuOpen)
    const closeMobileMenu = () => setMobileMenuOpen(false)
    const { t } = useTranslation()

    return (
        <div className='navbar'>
            <div className='nav'>
                <div className='logo-container'>
                    <NavLink exact to='/'>
                        <Logo className='logo' role='img' aria-label='shotbyrix.com logo' />
                    </NavLink>
                </div>
                <ul className={isMobileMenuOpen ? 'nav-options active' : 'nav-options'}>
                    <li className='option' onClick={closeMobileMenu}>
                        <NavLink exact to='/'>{t('home').toUpperCase()}</NavLink>
                    </li>
                    <li className='option' onClick={closeMobileMenu}>
                        <NavLink exact to='/about'>{t('about').toUpperCase()}</NavLink>
                    </li>
                    <li className='option' onClick={closeMobileMenu}>
                        <NavLink exact to='/contact'>{t('contact').toUpperCase()}</NavLink>
                    </li>
                </ul>
            </div>
            <div className='mobile-menu' onClick={toggleMobileMenu}>
                {isMobileMenuOpen ? (
                    <CloseMenu className='menu-icon' />
                ) : (
                    <MenuIcon className='menu-icon' />
                )}
            </div>
        </div>
    )
}

export default Navbar