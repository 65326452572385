import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ReactComponent as LeftArrow } from '../assets/left_arrow.svg'
import { ReactComponent as RightArrow } from '../assets/right_arrow.svg'
import CarouselImage from './CarouselImage'
import '../css/carousel.css'

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
      devicePixelRatio: undefined
    });

    useEffect(() => {
        function handleResize() {
            if (windowSize.width === undefined) {
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                    devicePixelRatio: window.devicePixelRatio
                });
            }
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, [windowSize.width]);
    return windowSize;
}

const Carousel = (props) => {
    const [isLeftArrowVisible, setLeftArrowVisible] = useState(false)
    const [isRightArrowVisible, setRightArrowVisible] = useState(true)
    const [currentImageIndex, setCurrentImageIndex] = useState(0)
    const imagesList = props.images
    const containerRef = useRef()
    const imagesRef = useRef([])
    const [isScrollingLeft, setScrollingLeft] = useState(false)
    const [isScrollingRight, setScrollingRight] = useState(false)
    const [isCarouselMoving, setCarouselMoving] = useState(false)
    let windowSize = useWindowSize()

    const leftClick = useCallback((e) => {
        e.preventDefault()
        if (!isCarouselMoving && !isScrollingLeft && (currentImageIndex > 0)) {
            setCarouselMoving(true)
            setScrollingLeft(true)
            const currentImage = imagesRef.current[currentImageIndex - 1]
            const prevImageX = currentImage.offsetLeft
            const translationX = (prevImageX - 25) > 0 ? (prevImageX - 25) : 0
            containerRef.current.style.transform = `translateX(-${translationX}px)`
            setCurrentImageIndex(currentImageIndex - 1)
            setRightArrowVisible(true)
        }
    }, [currentImageIndex, isScrollingLeft, isCarouselMoving])

    const rightClick = useCallback((e) => {
        e.preventDefault()
        if (!isCarouselMoving && !isScrollingRight && (currentImageIndex < (imagesList.length - 1))) {
            setCarouselMoving(true)
            setScrollingRight(true)
            const currentImage = imagesRef.current[currentImageIndex + 1]
            var translationX = currentImage.offsetLeft - 25
            containerRef.current.style.transform = `translateX(-${translationX}px)`
            setCurrentImageIndex(currentImageIndex + 1)
            setLeftArrowVisible(true)
        }
    }, [currentImageIndex, isScrollingRight, isCarouselMoving, imagesList.length])

    const handleKeyDown = useCallback((e) => {
        if (e.key === 'ArrowLeft')
            leftClick(e)
        else if (e.key === 'ArrowRight')
            rightClick(e)
    }, [leftClick, rightClick])

    const handleTransitionEnd = (e) => {
        if (currentImageIndex === 0) {
            setLeftArrowVisible(false)
        }
        if (currentImageIndex === (imagesList.length - 1)) {
            setRightArrowVisible(false)
        }
        setScrollingLeft(false)
        setScrollingRight(false)
        setCarouselMoving(false)
    }

    useEffect(() => {
        imagesRef.current = imagesRef.current.slice(0, imagesList.length)
        window.addEventListener('keydown', handleKeyDown)
        return () => window.removeEventListener('keydown', handleKeyDown)
    }, [imagesList.length, handleKeyDown])

    return (
        <div className='carousel'>
            <div className='carousel-controls'>
                <div className={isLeftArrowVisible ? 'control-left' : 'control-left hide'} onClick={isLeftArrowVisible ? leftClick : null}>
                    <LeftArrow className='left-icon' />
                </div>
                <div className={isRightArrowVisible ? 'control-right' : 'control-right hide'} onClick={isRightArrowVisible ? rightClick : null}>
                    <RightArrow className='right-icon' />
                </div>
            </div>
            <div className='carousel-images' ref={containerRef} onTransitionEnd={e => handleTransitionEnd(e)}>
                {imagesList.map((image, i) =>
                    <div className='image-container' key={i} ref={el => imagesRef.current[i] = el} style={{aspectRatio: image.width/image.height}}>
                        <CarouselImage image={image} i={i} ref={imagesRef} windowSize={windowSize} />
                    </div>
                )}
            </div>
        </div>
    )
}

export default Carousel