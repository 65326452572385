import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: 'en',
        react: {
          useSuspense: true,
          transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'u']
        },
        interpolation: {
          escapeValue: false
        },
        detection: {
          checkWhitelist: true
        }
    })

export default i18n