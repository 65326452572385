import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import './css/about.css'

const About = () => {
    const { t } = useTranslation()

    return(
        <>
            <Helmet>
                <title>{t('about').toUpperCase()} — Riccardo Porcu' Photography</title>
            </Helmet>
            <div className='about-container'>
                <div className='about-inner-container'>
                    <div className='about-image'>
                        <img src='shotbyrix-profile-pic.jpg' alt='Riccardo Porcù profile' />
                    </div>
                    <div className='about-description'>
                        <h1>{t('about')}</h1>
                        <br/>
                        <p>{t('about-description-1')}</p>
                        <br/>
                        <p>{t('about-description-2')}</p>
                        <br/>
                        <p>{t('about-description-3')}</p>
                        {/* <br/>
                        <p>{t('about-description-4')}</p> */}
                        <br/><br/>
                        <h2>{t('contact')}</h2>
                        <br/>
                        <p><a href='mailto:shotbyrix@gmail.com'>email: shotbyrix@gmail.com</a></p>
                        <br/>
                        <a href='https://www.instagram.com/shotbyrix' target='_blank' rel='noreferrer'><u>instagram</u></a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About