import React, { useEffect, useRef } from 'react'
import '../css/carousel-image.css'

const CarouselImage = React.forwardRef((props, ref) => {
    let imgRef = useRef()

    useEffect(() => {
        if (props.windowSize.width !== undefined) {
            if (props.windowSize.width > 767) {
                imgRef.current.src = 'https://ik.imagekit.io/rqxrav2shzg/tr:h-' + 600 * props.windowSize.devicePixelRatio + ',q-100/' + props.image.name
            } else {
                imgRef.current.src = 'https://ik.imagekit.io/rqxrav2shzg/tr:w-' + props.windowSize.width * props.windowSize.devicePixelRatio + ',q-100/' + props.image.name
            }
        }
    }, [ref, props.image, props.i, props.windowSize])

    return (
        <img
            ref={imgRef}
            className='image'
            width={props.image.width}
            height={props.image.height}
            loading='lazy'
            alt={'riccardo-porcu-photographer-florence-' + props.image.name.slice(0, -4)} />
    )
})

export default CarouselImage