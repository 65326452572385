import './css/app.css'
import Navbar from './components/Navbar'
import { Suspense } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { useGA4React } from 'ga-4-react'
import Home from './Home'
import About from './About'
import Contact from './Contact'
import './i18nextConf'

const App = () => {
  useGA4React("G-CK7SGVKXJT")
  
  return (
    <Suspense fallback={<div>Loading...</div>} maxDuration={5000}>
      <BrowserRouter>
        <div className="app">
          <Navbar />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/contact" component={Contact} />
            <Route render={() => <Redirect to="/" />} />
          </Switch>
        </div>
      </BrowserRouter>
    </Suspense>
  )
}

export default App;
